<template >
  <div class="containter">
    <div class="headerFixed">
      <div class="daohangLine">
        <img src="./imgs/logo2.png" class="mysj_logo_img" />
        <div class="openOrCloseArea">
          <img
            v-if="!openMenu"
            @click="changeOpenMenu(true)"
            src="./imgs/openMenu.png"
            style="width:100%;height:100%;"
          />
          <img
            v-if="openMenu"
            @click="changeOpenMenu(false)"
            src="./imgs/cha.jpg"
            style="width:100%;height:100%;"
          />
        </div>
      </div>
    </div>
    <van-popup
      v-model="openMenu"
      position="top"
      :style="{ height: '10rem',backgroundColor:'#1E1E1E',overflowY :'auto'}"
    >
      <div
        v-for="item in openMenuList"
        :key="item.id"
        @click="locationToPlace(item.id)"
        class="openMenuTabItem"
      >
        <div class="openMenuTabItemTitle">
          <div class="openMenuTabItemTitleLine">{{item.title}}</div>
          <div v-if="item.ohter" class="openMenuTabItemOtherLine">{{item.ohter}}</div>
        </div>
        <img src="./imgs/arrow_right.png" class="arrow_right" />
      </div>
      <div class="openMenuTabTelLine">售前咨询热线：400-663-7058</div>
      <div class="height110"></div>
    </van-popup>
    <div ref="scrollTab" class="height50"></div>
    <div class="bannerArea">
      <van-swipe class="my-swipe" lazy-render :autoplay="4000" :show-indicators="false">
        <van-swipe-item v-for="item in bannersList" :key="item.id">
          <!-- <img
              :src="item.imgUrl"
              style="width:100%;height:100%;"
              @click="openBannerPage(item.linkUrl)"
          />-->
          <img :src="item.linkUrl" class="bannerImg" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div ref="scrollTab1">
      <div class="solvePlanTab">
        <div class="solvePlanTabTitle">解决方案</div>
        <div class="solvePlanTabSmallTitle">让每个决策都有数据支撑</div>
        <div class="solvePlanLunList">
          <img
            src="./imgs/arrowLeft.png"
            @click="solvePlanTurnRightOrLeft('left')"
            class="solvePlanLunListLeft"
          />
          <div class="solvePlanLunCenter">
            <van-swipe
              class="my-swipe-serve"
              lazy-render
              :show-indicators="false"
              :autoplay="1000000"
              ref="myServeSwipe"
            >
              <van-swipe-item
                style="display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;"
                v-for="item in solveImgList"
                :key="item.id"
              >
                <img :src="item.img" class="solvePlanLunCenterImg" />
                <div class="solvePlanLunCenterFont">{{item.name}}</div>
              </van-swipe-item>
            </van-swipe>
          </div>
          <img
            src="./imgs/arrowRight.png"
            @click="solvePlanTurnRightOrLeft('right')"
            class="solvePlanLunListRight"
          />
        </div>
      </div>
      <div class="greyLine10"></div>
      <div class="hotInformationTab">
        <div class="hotInformationTabTitle">
          <span class="hotInformationTabTitleFont">热点资讯</span>
          <img
            @click="gotoInfoList"
            src="./imgs/arrow_circleBlack.png"
            class="hotInformationTabTitleImg"
          />
        </div>
        <div class="serverSupportImg">
          <div class="serverSupportImgList">
            <img
              v-for="item in informationList"
              :key="item.id"
              :src="item.img"
              class="serverSupportLunBoImg"
              @click="gotoInfoDetail(item.id)"
            />
          </div>
        </div>
      </div>
      <div class="greyLine10"></div>
      <!-- <div class="baseLine"></div> -->
      <van-collapse v-model="solvePlanSelect" accordion>
        <van-collapse-item
          v-for="item in solvePlanSelectList"
          :key="item.id"
          :title="item.name"
          :name="item.id"
        >
          <div class="solvePlanSelectImgArea">
            <img :src="item.img" class="solvePlanSelectImg" />
            <!-- <div class="solvePlanSelectImgAreaDetail">了解详情</div> -->
          </div>
        </van-collapse-item>
      </van-collapse>
      <div class="divideLine"></div>
    </div>
    <div ref="scrollTab2">
      <div class="height24"></div>
      <div class="buildSettingTitle">建设背景</div>
      <div class="buildSettingSmallTitle">全新智慧城镇管理服务平台</div>
      <div class="height16"></div>
      <van-collapse v-model="buildSettingSelect" accordion>
        <van-collapse-item
          v-for="item in buildSettingSelectList"
          :key="item.id"
          :title="item.name"
          :name="item.id"
        >
          <div style="background-color:#F7F9FF;width:100%;">
            <img :src="item.img" class="buildSettingSelectimg" />
            <p class="buildSettingSelectContent">{{item.content}}</p>
            <div class="height24"></div>
          </div>
        </van-collapse-item>
      </van-collapse>
      <div class="divideLine"></div>
    </div>
    <div ref="scrollTab3">
      <div class="height24"></div>
      <div class="buildSettingTitle">服务支持</div>
      <div class="buildSettingSmallTitle">提升智能化决策能力</div>
      <div class="height16"></div>
      <div class="serverSupportImg">
        <!-- <van-swipe class="my-swipe" :autoplay="4000" :show-indicators="false">
          <van-swipe-item
            v-for="item in serverSupportList"
            :key="item.id"
            @click="openBannerPage(item.linkUrl)"
          >
            <img :src="item.linkUrl" class="serverSupportLunBoImg" />
          </van-swipe-item>
        </van-swipe>-->
        <div class="serverSupportImgList">
          <img
            v-for="item in serverSupportList"
            :key="item.id"
            :src="item.linkUrl"
            class="serverSupportLunBoImg"
          />
        </div>
      </div>
      <div class="serverSupportShowTab">
        <div v-for="item in serverSupportShowList" :key="item.id" class="serverSupportShowTabItem">
          <img :src="item.img" class="serverSupportShowTabItemImg" />
          <div class="serverSupportShowTabItemFont">{{item.name}}</div>
        </div>
      </div>
      <div class="divideLine"></div>
    </div>
    <div class="mysj-area" ref="scrollTab4">
      <div class="height24"></div>
      <div class="mysj-area-title">美云数据</div>
      <p class="mysj-p">{{mysjData}}</p>
    </div>
    <div class="couperationArea" ref="scrollTab5">
      <div class="height24"></div>
      <div class="buildSettingTitle">合作伙伴</div>
      <img src="./imgs/cooperation.png" class="coopearationImg" />
    </div>
    <div class="lastArea">
      <div class="fourServerArea">
        <div class="fourServerItem margin24Left">
          <img src="./imgs/fourServe_1.png" class="fourServeImg" />
          <div class="fourServeFont">开通即用</div>
        </div>
        <div class="fourServerItem">
          <img src="./imgs/fourServe_2.png" class="fourServeImg" />
          <div class="fourServeFont">定制化服务</div>
        </div>
        <div class="fourServerItem">
          <img src="./imgs/fourServe_3.png" class="fourServeImg" />
          <div class="fourServeFont">1V1大客户服务</div>
        </div>
        <div class="fourServerItem margin24Right">
          <img src="./imgs/fourServe_4.png" class="fourServeImg" />
          <div class="fourServeFont">7*24小时服务</div>
        </div>
      </div>
      <div class="lastBottomBg">
        <div v-for="item in lastBottomBgList" :key="item" class="lastBottomBgItem">{{item}}</div>
      </div>
      <bottom-list
        :bottomList="bottomList"
        :bottomListSelect="bottomListSelect"
        @changeBottomListSelect="changeBottomListSelect"
      ></bottom-list>
      <div class="bottomMsgArea">
        <div class="height43"></div>
        <img class="qrcode" src="./imgs/qrcode2.jpg" alt="">
        <div class="bottomMsgTel">售前咨询热线：400-663-7058</div>
        <div class="greyLine"></div>
        <div class="bottomMsgCompany">
          Copyright © 杭州美云数据有限公司 All rights reserved
          <a href="https://beian.miit.gov.cn" target="_blank" class="beian" style="color: #fff;">浙ICP备20014233号</a>
          <a href="https://beian.mps.gov.cn/#/query/webSearch?code=33011002017596" style="color: #fff;margin-left: 8px;" rel="noreferrer" target="_blank">浙公网安备33011002017596</a>
        </div>
      </div>
    </div>
    <banner-bottom></banner-bottom>
  </div>
</template>

<script>
import { Notify } from 'vant';
import BottomList from './bottomList';
import BannerBottom from './BannerBottom';
export default {
  name: 'MainPage',
  components: {
    BottomList,
    BannerBottom
  },
  data() {
    return {
      openMenu: false,
      // 头元素的高度
      headerHeight: 0,
      bannersList: [
        {
          id: 1,
          linkUrl: require('./imgs/banner1@2x.png')
        },
        {
          id: 2,
          linkUrl: require('./imgs/banner2@2x.png')
        },
        {
          id: 3,
          linkUrl: require('./imgs/banner3@2x.png')
        }
      ],
      solveImgList: [
        { id: 1, img: require('./imgs/solveIcon_1.png'), name: '数据接入' },
        { id: 2, img: require('./imgs/solveIcon_2.png'), name: '清洗融合' },
        { id: 3, img: require('./imgs/solveIcon_3.png'), name: '数据可视化' },
        { id: 4, img: require('./imgs/solveIcon_4.png'), name: '态势分析' },
        { id: 5, img: require('./imgs/solveIcon_5.png'), name: '决策依据' }
      ],
      selectSolveImg: 1,
      solvePlanSelectList: [
        {
          id: 1,
          img: require('./imgs/solvePlan_1.png'),
          name: '数据驾驶舱'
        },
        {
          id: 2,
          img: require('./imgs/solvePlan_2.png'),
          name: '智慧党建'
        },
        {
          id: 3,
          img: require('./imgs/solvePlan_3.png'),
          name: '垃圾分类'
        },
        {
          id: 4,
          img: require('./imgs/solvePlan_4.png'),
          name: '智慧文旅'
        },
        {
          id: 5,
          img: require('./imgs/solvePlan_5.png'),
          name: '时空印象'
        }
      ],
      solvePlanSelect: 0,
      buildSettingSelectList: [
        {
          id: 1,
          img: require('./imgs/buildSetting_1.png'),
          name: '国家政策引领',
          content:
            '党中央、国务院高度重视大数据发展，将大数据上升为我国国家战略之一。\n一、中共中央国务院关于深入推进城市执法体制改革改进城市管理工作的指导意见（中发〔2015〕37号）指出： 建立用数据说话、用数据决策、用数据管理、用数据创新的新机制。\n二、十九大之后不久，中共中央政治局就实施国家大数据战略进行第二次集体学习。 习近平总书记指出，善于获取数据、分析数据、运用数据，是领导干部做好工作的基本功。'
        },
        {
          id: 2,
          img: require('./imgs/buildSetting_2.png'),
          name: '地方政策不断出台',
          content:
            '中共浙江省委办公厅、浙江省人民政府办公厅印发《浙江省小城镇环境综合整治行动实施方案》，美丽城镇的建设主要目标是：到2020年，有100个左右的小城镇率先达到美丽城镇样板要求；到2020年，有300个左右小城镇达到美丽城镇样板要求，城乡融合发展体制初步建立；到2035年，城乡融合发展体制机制更加完善，全省小城镇高质量全面建成美丽城镇，同步高水平基本现代化。2019年9月，浙江发布《浙江省美丽城镇建设指南》，其中指出打造美丽城镇的重要意义：建设新时代美丽城镇，是推进“八八战略”再深化、改革开放再出发作出的重大部署，也是践行初心使命、实现人民对美好生活向往的实际行动。'
        },
        {
          id: 3,
          img: require('./imgs/buildSetting_3.png'),
          name: '城镇发展必然趋势',
          content:
            '随着美丽城镇管理工作的不断推进，数字化建设平台积累的数据不断增多，相关业务口系统成熟度不断提高，运用大数据技术实现城镇管理的决策科学化、治理精准化、服务高效化，是城镇管理发展的必然趋势！'
        }
      ],
      buildSettingSelect: 0,
      serverSupportList: [
        {
          id: 1,
          linkUrl: require('./imgs/serverSupport_1.png')
        },
        {
          id: 2,
          linkUrl: require('./imgs/serverSupport_2.png')
        },
        {
          id: 3,
          linkUrl: require('./imgs/serverSupport_3.png')
        },
        {
          id: 4,
          linkUrl: require('./imgs/serverSupport_4.png')
        }
      ],
      serverSupportShowList: [
        {
          id: 1,
          img: require('./imgs/serSupportIcon_1.png'),
          name: '多端协同'
        },
        {
          id: 2,
          img: require('./imgs/serSupportIcon_2.png'),
          name: '轻量化交付'
        },
        {
          id: 3,
          img: require('./imgs/serSupportIcon_3.png'),
          name: '流畅操作体验'
        }
      ],
      mysjData:
        '      杭州美云数据科技有限公司拥有近20年的数据处理和ICT领域经验，核心团队以阿里系和浙大系为主要构成。公司以行业领先的数字化转型技术与数据智能服务为核心，集成物联网、云计算、大数据、人工智能、区块链等新一代信息技术，为政府提供一体化政务服务解决方案。\n \n      公司的核心业务为智慧城市顶层设计、智慧城市应用开发、数字城镇、智慧园区的PAAS平台、政务服务标准化SAAS软件和增值定制化解决方案。公司基于大数据引擎，通过大规模机器学习和深度学习等技术，实现数据智能，并通过可视化技术呈现，服务于政务、城管、公安、医疗、旅游等行业。\n \n       美云数据以实现中国梦为愿景，始终坚持将技术领先与业务使命相结合，让智能、便捷的数字生活普惠世界。',
      lastBottomBgList: [
        '全方位产品解读',
        '专业技术咨询',
        '成功案例分享',
        '成熟解决方案'
      ],
      bottomListSelect: 0,
      bottomList: [
        {
          id: 1,
          name: '基础应用',
          list: ['组织架构', '数据申报', '管理工作台']
        },
        {
          id: 2,
          name: '环境美应用',
          list: ['智能排水', '垃圾分类', '智慧公厕', '智慧停车', '智慧消防']
        },
        {
          id: 3,
          name: '生活美应用',
          list: ['智慧路灯', '智慧医疗', '智慧养老', '智慧校园', '邻里互助']
        },
        {
          id: 4,
          name: '产业美应用',
          list: ['智慧物流', '智慧工业', '智慧农业', '产业集群', '一企一档']
        },
        {
          id: 5,
          name: '人文美应用',
          list: ['智慧景区', '智慧文旅', '智慧党建', '小区改造', '绿化管理']
        },
        {
          id: 6,
          name: '治理美应用',
          list: ['智慧治理', '智慧城管', '智慧小区', '智慧安防', '智慧物业']
        }
      ],
      openMenuList: [
        {
          id: 1,
          title: '解决方案',
          ohter: '让每个决策都有数据支撑'
        },
        {
          id: 2,
          title: '建设背景',
          ohter: '全新智慧城镇管理服务平台'
        },
        {
          id: 3,
          title: '服务支持',
          ohter: '提升智能化决策能力'
        },
        {
          id: 4,
          title: '美云数据'
        },
        {
          id: 5,
          title: '合作数据'
        }
      ],
      // 热点资讯的四张图
      informationList: [
        {
          id: 1,
          img: require('./imgs/info_list_1.png')
        },
        {
          id: 2,
          img: require('./imgs/info_list_2.png')
        },
        {
          id: 3,
          img: require('./imgs/info_list_3.png')
        },
        {
          id: 4,
          img: require('./imgs/info_list_4.png')
        }
      ]
    };
  },
  watch: {},
  mounted() {
    this.$nextTick(function() {
      const offsetHeight = this.$refs.scrollTab.offsetHeight;
      this.headerHeight = offsetHeight;
      const num = this.$route.query.id || 0;
      if (num) {
        this.locationToPlace(Number(num));
      } else {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      }
    });
  },
  created() {},
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    // fgsdgsd
    changeOpenMenu(value) {
      console.log(value);
      this.openMenu = value;
    },
    // 定位到具体位置
    locationToPlace(num) {
      this.solvePlanSelect = 0;
      this.buildSettingSelect = 0;
      this.bottomListSelect = 0;
      let scrollTop = 0;
      switch (num) {
        case 1:
          this.$nextTick(function() {
            scrollTop = this.$refs.scrollTab1.offsetTop;
            // document.body.scrollTop = document.documentElement.scrollTop = 190;
            document.body.scrollTop = document.documentElement.scrollTop =
              scrollTop - this.headerHeight;
          });
          break;
        case 2:
          this.$nextTick(function() {
            // document.body.scrollTop = document.documentElement.scrollTop = 927;
            scrollTop = this.$refs.scrollTab2.offsetTop;
            document.body.scrollTop = document.documentElement.scrollTop =
              scrollTop - this.headerHeight;
          });
          break;
        case 3:
          this.$nextTick(function() {
            // document.body.scrollTop = document.documentElement.scrollTop = 1182;
            scrollTop = this.$refs.scrollTab3.offsetTop;
            document.body.scrollTop = document.documentElement.scrollTop =
              scrollTop - this.headerHeight;
          });
          break;
        case 4:
          this.$nextTick(function() {
            // document.body.scrollTop = document.documentElement.scrollTop = 1559;
            scrollTop = this.$refs.scrollTab4.offsetTop;
            document.body.scrollTop = document.documentElement.scrollTop =
              scrollTop - this.headerHeight;
          });
          break;
        case 5:
          this.$nextTick(function() {
            // document.body.scrollTop = document.documentElement.scrollTop = 1979;
            scrollTop = this.$refs.scrollTab5.offsetTop;
            document.body.scrollTop = document.documentElement.scrollTop =
              scrollTop - this.headerHeight;
          });
          break;
      }
      this.openMenu = false;
    },
    // 打开banner图的链接
    openBannerPage(url) {
      window.open(url);
    },
    // 解决方案的图的点击轮换
    solvePlanTurnRightOrLeft(direction) {
      if (direction === 'left') {
        this.$refs.myServeSwipe.prev();
        // if (this.selectSolveImg === 1) {
        //   this.selectSolveImg = 5;
        // } else {
        //   this.selectSolveImg -= 1;
        // }
      } else {
        this.$refs.myServeSwipe.next();
        // if (this.selectSolveImg === 5) {
        //   this.selectSolveImg = 1;
        // } else {
        //   this.selectSolveImg += 1;
        // }
      }
    },
    changeBottomListSelect(value) {
      this.bottomListSelect = Number(value);
    },
    // 去资讯列表页
    gotoInfoList() {
      this.$router.push({ name: 'infoList' });
    },
    // 去资讯详情页
    gotoInfoDetail(id) {
      this.$router.push({ name: 'infoDetail', query: { id: id } });
    }
  }
};
</script>
<style lang="scss" scoped>
$px: 1rem/37.5;

/deep/ .van-collapse-item__content {
  padding: 0px;
}
/deep/ .van-cell {
  padding: 12 * $px 0.42667rem;
  color: #333333;
}
/deep/ .van-collapse-item__title--expanded {
  color: #ffa500;
}
/deep/ .van-popup--top {
  top: 50 * $px;
  left: 0;
  width: 100%;
}
.containter {
  width: 100%;
}
.headerFixed {
  width: 100%;
  height: 50 * $px;
  background-color: #ffffff;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 5000;
}
.titleLine {
  width: 100%;
  height: 44 * $px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
  font-size: 18 * $px;
}
.daohangLine {
  width: 100%;
  height: 50 * $px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mysj_logo_img {
  width: 107 * $px;
  height: 32.65 * $px;
  margin-left: 24 * $px;
}
.openOrCloseArea {
  width: 24 * $px;
  height: 24 * $px;
  margin-right: 28 * $px;
  display: flex;
}
.height94 {
  width: 100%;
  height: 94 * $px;
}
.height50 {
  width: 100%;
  height: 50 * $px;
}
.openMenuTabItem {
  width: 100%;
  height: 93 * $px;
  border-bottom: #333333 0.6 * $px solid;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bannerArea {
  width: 100%;
  height: 190 * $px;
  overflow: hidden;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20 * $px;
  line-height: 190 * $px;
  text-align: center;
  height: 100%;
  background-color: rgb(244, 215, 219);
}
.bannerImg {
  height: 190 * $px;
}
.solvePlanTab {
  width: 100%;
  height: 227 * $px;
}
.qrcode{
  display: block;
  width: 130*$px;
  height: 130*$px;
  margin: 0 auto;
}
.solvePlanTabTitle {
  width: 100%;
  height: 28 * $px;
  margin-top: 24 * $px;
  font-size: 20 * $px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.solvePlanTabSmallTitle {
  width: 100%;
  height: 17 * $px;
  margin-top: 6 * $px;
  font-size: 12 * $px;
  color: #999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.solvePlanLunList {
  width: 100%;
  height: 128 * $px;
  margin-top: 16 * $px;
  display: flex;
  justify-content: space-between;
}
.solvePlanLunListLeft {
  width: 36 * $px;
  height: 36 * $px;
  margin-left: 26 * $px;
  margin-top: 32 * $px;
  display: flex;
}
.solvePlanLunListRight {
  width: 36 * $px;
  height: 36 * $px;
  margin-right: 26 * $px;
  margin-top: 32 * $px;
  display: flex;
}
.solvePlanLunCenter {
  width: 100 * $px;
  height: 128 * $px;
}
.solvePlanLunCenterImg {
  width: 100 * $px;
  height: 100 * $px;
}
.solvePlanLunCenterFont {
  width: 100%;
  height: 20 * $px;
  font-size: 14 * $px;
  color: #ffa500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.baseLine {
  width: 100%;
  height: 1 * $px;
  background-color: #eeeeee;
}
.solvePlanSelectImgArea {
  width: 100%;
  height: 174 * $px;
  position: relative;
}
.solvePlanSelectImg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}
.solvePlanSelectImgAreaDetail {
  width: 84 * $px;
  height: 32 * $px;
  position: absolute;
  border: #ffffff 1 * $px solid;
  border-radius: 2 * $px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 14 * $px;
  top: 94 * $px;
  left: 24 * $px;
}
.divideLine {
  width: 100%;
  height: 10 * $px;
  background-color: #f5f5f5;
}
.height24 {
  width: 100%;
  height: 24 * $px;
}
.buildSettingTitle {
  width: 100%;
  height: 28 * $px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20 * $px;
  color: #000000;
}
.buildSettingSmallTitle {
  width: 100%;
  height: 17 * $px;
  color: #999999;
  font-size: 12 * $px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6 * $px;
}
.height16 {
  width: 100%;
  height: 16 * $px;
}
.buildSettingSelectimg {
  width: 359 * $px;
  height: 240 * $px;
  margin-left: 8 * $px;
}
.buildSettingSelectContent {
  width: 327 * $px;
  font-size: #666666;
  font-size: 12 * $px;
  line-height: 150%;
  white-space: pre-wrap;
  margin: 0 auto;
}
.serverSupportImg {
  width: 100%;
  height: 140 * $px;
  overflow-x: scroll;
}
.serverSupportImgList {
  width: 1268 * $px;
  height: 100%;
  overflow: hidden;
}
.serverSupportLunBoImg {
  width: 300 * $px;
  height: 140 * $px;
  margin-left: 17 * $px;
}
.serverSupportShowTab {
  width: 100%;
  height: 136 * $px;
  display: flex;
  justify-content: center;
}
.serverSupportShowTabItem {
  width: 118 * $px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.serverSupportShowTabItemImg {
  width: 48 * $px;
  height: 48 * $px;
  margin-top: 32 * $px;
}
.serverSupportShowTabItemFont {
  height: 20 * $px;
  width: 100%;
  color: #333333;
  font-size: 14 * $px;
  margin-top: 12 * $px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mysj-area {
  width: 100%;
  height: 420 * $px;
  background: url(./imgs/mysj_bg.png) no-repeat;
  background-size: 100% 100%;
}
.mysj-area-title {
  width: 100%;
  height: 28 * $px;
  font-size: 20 * $px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mysj-p {
  width: 327 * $px;
  margin: 24 * $px auto;
  color: #ffffff;
  font-size: 12 * $px;
  white-space: pre-wrap;
  line-height: 150%;
}
.couperationArea {
  width: 100%;
  height: 338 * $px;
}
.coopearationImg {
  width: 100%;
  height: 260 * $px;
  margin-top: 12 * $px;
}
.lastArea {
  width: 100%;
  background-color: #1e1e1e;
}
.fourServerArea {
  padding-left: 9.5 * $px;
  padding-right: 9.5 * $px;
  height: 130 * $px;
  display: flex;
  justify-content: space-between;
}
.fourServerItem {
  width: 60 * $px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fourServeImg {
  width: 44 * $px;
  height: 44 * $px;
  margin-top: 24 * $px;
}
.fourServeFont {
  width: 100%;
  text-align: center;
  line-height: 150%;
  font-size: 12 * $px;
  color: #ffffff;
  margin-top: 5 * $px;
}
.margin24Left {
  margin-left: 24 * $px;
}
.margin24Right {
  margin-right: 24 * $px;
}
.lastBottomBg {
  width: 100%;
  height: 150 * $px;
  background: url(./imgs/lastBottomBg.png) no-repeat;
  background-size: 100% 100%;
}
.lastBottomBgItem {
  width: 50%;
  height: 22 * $px;
  margin-top: 32 * $px;
  margin-bottom: 10 * $px;
  float: left;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 14 * $px;
}
.bottomMsgArea {
  width: 100%;
  height: 372 * $px;
}
.bottomMsgTel {
  width: 343 * $px;
  height: 48 * $px;
  background-color: #ffffff;
  border-radius: 4 * $px;
  font-size: 18 * $px;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  margin-top: 20*$px;
}
.greyLine {
  width: 327 * $px;
  height: 1 * $px;
  background-color: #333333;
  margin: 24 * $px auto 14 * $px;
}
.height43 {
  height: 43 * $px;
  width: 100%;
}
.bottomMsgCompany {
  width: 250 * $px;
  margin: 0 auto;
  line-height: 150%;
  text-align: center;
  font-size: 10 * $px;
  color: #ffffff;
}
.openMenuTabItemTitle {
  width: 250 * $px;
  height: 100%;
  margin-left: 24 * $px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.arrow_right {
  width: 12.5 * $px;
  height: 12.5 * $px;
  margin-right: 27 * $px;
}
.openMenuTabItemTitleLine {
  color: #ffffff;
  font-size: 16 * $px;
}
.openMenuTabItemOtherLine {
  color: rgb(64, 64, 64);
  font-size: 12 * $px;
  margin-top: 7 * $px;
}
.openMenuTabTelLine {
  margin-left: 24 * $px;
  margin-top: 77 * $px;
  color: #ffffff;
  font-size: 14 * $px;
}
.hotInformationTab {
  width: 100%;
  height: 240 * $px;
  background-color: #ffffff;
}
.greyLine10 {
  width: 100%;
  height: 10 * $px;
  background-color: #f5f5f5;
}
.hotInformationTabTitle {
  width: 100%;
  height: 28 * $px;
  display: flex;
  align-items: center;
  color: #000000;
  font-size: 20 * $px;
  margin-top: 24 * $px;
  margin-bottom: 25 * $px;
}
.hotInformationTabTitleImg {
  width: 22 * $px;
  height: 22 * $px;
  margin-left: 6 * $px;
}
.hotInformationTabTitleFont {
  margin-left: 147 * $px;
}
.hotInformationTabListOut {
  width: 100%;
  margin-left: 16 * $px;
  height: 140 * $px;
  overflow-x: scroll;
}
.hotInformationTabList {
  width: 1272 * $px;
  height: 100%;
}
.hotInformationTabListImg {
  width: 300 * $px;
  height: 100%;
  margin-right: 18 * $px;
}
.my-swipe-serve {
  width: 100%;
  height: 100%;
}
.height110 {
  width: 100%;
  height: 110 * $px;
}
</style>





